import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const ImgForProduct = ({ name, ...props }) => {
  const data = useStaticQuery(graphql`
    query imgforproduct {
      img1: file(relativePath: { eq: "cadre_carre.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
      aluca: file(relativePath: { eq: "cadre_aluca.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
      astral_arriere: file(relativePath: { eq: "astral-arriere.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      vitrine: file(relativePath: { eq: "vitrine.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      vitrine_montage: file(relativePath: { eq: "vitrine-montage.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      astral_face: file(relativePath: { eq: "astral-face.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      astral_face2: file(relativePath: { eq: "astral-face2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      astral_zoom: file(relativePath: { eq: "astral-zoom-accroche.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      cristal_face: file(relativePath: { eq: "cristal-face.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }

      cristal_arriere: file(relativePath: { eq: "cristal-arriere.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)
  return <GatsbyImage image={getImage(data[name])} {...props} />
}

ImgForProduct.propTypes = {
  name: PropTypes.string,
}
export default ImgForProduct
