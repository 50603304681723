import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const ImgProfil = ({ id, profil, ...props }) => {
  const data = useStaticQuery(graphql`
    query imgProfil {
      allFile(filter: { relativePath: { regex: "/^nielsen/" } }) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 150)
          }
        }
      }
    }
  `)

  let match = null
  match = useMemo(
    () =>
      data.allFile.nodes.find(
        e => (id !== null && id === parseInt(e.name)) || (profil !== null && "Profil_" + profil.replace("ALU ", "").replace("N", "") + "_3D" === e.name)
      ),
    [data, id, profil]
  )

  //console.log(id, props, match)
  return <GatsbyImage image={getImage(match)} {...props} alt="photo du profil" />
}
ImgProfil.defaultProps = {
  id: null,
  profil: null,
}
ImgProfil.propTypes = {
  id: PropTypes.number,
  profil: PropTypes.string,
}
export default ImgProfil
