import React from "react"
import { setKitMontage } from "../../actions/cadre"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faHandPointRight, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { getEtape } from "@bko/optionsproduits"

const ChoixKitMontage = props => {
  const pageProduit = useSelector(state => state.PageProduit)
  const photosR = useSelector(state => state.photosR)
  const dispatch = useDispatch()
  return (
    <>
      <div className="columns is-multiline is-vcentered">
        <div className="h2">
          {getEtape(props.product, pageProduit, photosR) === "kitMontage" && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}
          Je souhaite un kit de montage
          <br />
        </div>
        Ci-dessous la vidéo vous montrant le montage par cheville proposé :
        <iframe
          id="ytplayer"
          className="mt-4 mb-3"
          title="vidéo découvrir kit montage"
          type="text/html"
          width="100%"
          height="360"
          src="https://youtube.com/embed/1DaSM3ll-so?rel=0"
          frameBorder="0"
        />
        <div className="choix_isorel yesno ml-2" style={{ height: "2rem" }}>
          <div
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});dispatch(setKitMontage(true))}}
            role="radio"
            aria-checked={pageProduit.kitMontage}
            tabIndex={0}
            className={`${pageProduit.kitMontage ? "selected" : ""}`}
            onKeyPress={() => {}}
          >
            <span>Oui</span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});dispatch(setKitMontage(false))}}
            role="radio"
            aria-checked={pageProduit.kitMontage === false}
            tabIndex={0}
            className={`${pageProduit.kitMontage === false ? "selected" : ""}`}
            onKeyPress={() => {}}
          >
            <span>Non</span>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    </>
  )
}
ChoixKitMontage.propTypes = {
  product: PropTypes.object,
  pa: PropTypes.array,
}
export default ChoixKitMontage
