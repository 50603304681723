import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { setRehausseColor } from "../../actions/cadre"
import ReactTooltip from "react-tooltip"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faHandPointRight, faInfoCircle, faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { getPrix } from "../../services/panier"
import { rehausses, tva, couleurs_rehausse } from "../../profcadresprix/index-legacy"
import Fade from "react-reveal/Fade"
import { getEtape } from "@bko/optionsproduits"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ChoixRehausse = (props) => {
  const [rehausse, setRehausse] = useState(null)
  const pageProduit = useSelector((s) => s.PageProduit)
  const photosR = useSelector((s) => s.photosR)
  const dispatch = useDispatch()
  const {baguette_ext} = props
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  return (
    <>
      <div className="is-flex is-multiline" style={{ alignItems: "center" }}>
        <div className="h2">
          {getEtape({...baguette_ext,cat_id:baguette_ext.cat_id_originale }, pageProduit, photosR) === "rehausse" && !rehausse && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}
          Je souhaite une réhausse
          {isMounted ? <ReactTooltip id="fi" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
          <span
            data-multiline="true"
            data-for="fi"
            data-tip="Permet un espace libre de 20 ou 30mm entre le verre et le fond du cadre (glissée dans le cadre, non fixée)"
          >
            <small>
              <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
            </small>
          </span>
        </div>
        <div className="yesno ml-2" style={{ height: "2rem" }}>
          <div
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});setRehausse(true)}}
            role="radio"
            aria-checked={rehausse}
            tabIndex={0}
            className={`${rehausse ? "selected" : ""}`}
            onKeyPress={() => {}}
          >
            <span>Oui</span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div
            onClick={(e) => {
              window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
              dispatch(setRehausseColor("none", null))
              setRehausse(false)
            }}
            role="radio"
            aria-checked={rehausse === false}
            tabIndex={0}
            className={`${rehausse === false ? "selected" : ""}`}
            onKeyPress={() => {}}
          >
            <span>Non</span>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>

      {rehausse && (
        <Fade bottom>
          <ChoixTypeRehausse {...props} />
        </Fade>
      )}
    </>
  )
}

export const ChoixTypeRehausse = (props) => {
  const photosR = useSelector((state) => state.photosR)
  const pageProduit = useSelector((state) => state.PageProduit)
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)
  const {baguette_ext} = props
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  return collapsed ? (
    <div className="h2">
      Je souhaite une réhausse{" "}
      <span tabIndex={0} role="button" onKeyPress={() => {}} onClick={() => setCollapsed(false)}>
        <span>
          {rehausses[pageProduit.rehausse].title} {pageProduit.rehausse !== null ? Object.values(couleurs_rehausse)[pageProduit.rehausseColor].title : ""}
        </span>
        <div className="button is-small ml-2">
          <FontAwesomeIcon icon={faPencilAlt} size="sm" className="mr-2" />
          Modifier
        </div>
      </span>
    </div>
  ) : (
    <div className="pl-3 pr-2" style={{ marginLeft: "-0.75rem" }}>
      <div className="h2">
        {getEtape({...baguette_ext,cat_id:baguette_ext.cat_id_originale }, pageProduit, photosR) === "rehausse" && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}Option réhausse
      </div>
      <table className="table td-vcentered" style={{ marginTop: "0.5rem" }}>
        <thead>
          <tr>
            <th>Réhausse</th>
            <th className="has-text-centered">Prix unitaire HT</th>
            <th className="has-text-centered">Prix unitaire TTC</th>
            <th className="has-text-centered">
              <span className="is-hidden-mobile">Sélection</span>
            </th>
            {pageProduit.rehausse !== null ? <th></th> : null}
          </tr>
        </thead>
        <tbody>
          {Object.keys(rehausses)
            .filter(
              (k) =>
                k !== "none" &&
                (k !== "h30mm" || (![370, 106, 480].includes(props.baguette_ext.fa_id) && ![1504, 1503, 1505, 2485, 2486].includes(props.baguette_ext.ba_id)))
            )
            .map((k) => (
              <tr className={pageProduit.rehausse == k ? "serviceSelected" : ""} key={`p-${k}`} style={{ cursor: "pointer" }}>
                <td>
                  <span className="columns is-vcentered" style={{ padding: 7 }}>
                    {rehausses[k].title}
                  </span>
                </td>
                <td className="has-text-centered">
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(getPrix(props.baguette_ext, { ...pageProduit, rehausse: k }, props.produits_annexes, photosR) / tva)}
                </td>
                <td className="has-text-centered">
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(getPrix(props.baguette_ext, { ...pageProduit, rehausse: k }, props.produits_annexes, photosR))}
                </td>
                <td className="has-text-centered">
                  {k !== "none" ? (
                    <div className="colors_rehausse">
                      {Object.values(couleurs_rehausse).map((color, key2) => (
                        <>
                          <div
                            data-tip
                            data-for={`tt-${k}-${key2}`}
                            className={`color ${pageProduit.rehausseColor == key2 && pageProduit.rehausse == k ? "selected" : ""}`}
                            key={`d-${k}-${key2}`}
                            onClick={() => dispatch(setRehausseColor(k, key2))}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={key2}
                            style={{ background: color.color, overflow: "hidden" }}
                          >
                            {props.rehausses && typeof color.ba_id_img !== "undefined" ? (
                              <GatsbyImage
                                image={getImage(props.rehausses.find((e) => e.ba_id == color.ba_id_img).imgCoin)}
                                style={{ top: 0 }}
                                alt={`Réhausse ${color.title}`}
                              />
                            ) : null}
                            {isMounted ? <ReactTooltip id={`tt-${k}-${key2}`} place="top" key={`di-${k}-${key2}`} type="info" effect="solid">
                              <div style={{ textAlign: "center" }}>{color.title}</div>
                            </ReactTooltip> : null}
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <input
                      type="checkbox"
                      name="selectService"
                      value={1}
                      onChange={() => {
                        setCollapsed(true)
                        dispatch(setRehausseColor(k, null))
                      }}
                      checked={pageProduit.rehausse == k}
                    />
                  )}
                </td>
                {pageProduit.rehausse == k && pageProduit.rehausse !== 0 ? <td>{Object.values(couleurs_rehausse)[pageProduit.rehausseColor].title}</td> : null}
              </tr>
            ))}
        </tbody>
      </table>
      {/**<div className="has-text-centered">
        <button className="button is-primary" onClick={() => pageProduit.rehausse !== null && setCollapsed(true)} data-cy="valider-rehausse">
          Valider
        </button>
      </div>*/}
    </div>
  )
}

ChoixTypeRehausse.propTypes = {
  baguette_ext: PropTypes.object,
  produits_annexes: PropTypes.array,
  pu_ht: PropTypes.number,
  rehausses: PropTypes.object,
}

ChoixRehausse.propTypes = {
  baguette_ext: PropTypes.object,
  produits_annexes: PropTypes.array,
  pu_ht: PropTypes.number,
  rehausses: PropTypes.object,
}

export default ChoixRehausse
