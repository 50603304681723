import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faHandPointRight, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { setIsorel } from "../../actions/cadre"
import ReactTooltip from "react-tooltip"
import { getEtape } from "@bko/optionsproduits"

const ChoixFondIsorel = (props) => {
  const pageProduit = useSelector((state) => state.PageProduit)
  const photosR = useSelector((state) => state.photosR)
  const dispatch = useDispatch()

  const { product } = props

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  // sur alu coupé assemblé, l'isorel est obligatoire
  useEffect(() => {
    setTimeout(() => {
      if (pageProduit.service === "coupe_assemble_option" && props.product.cat_id_originale === 6) dispatch(setIsorel(true))
    }, 100)
  }, [pageProduit.service, pageProduit.isorel])
  return (
    <>
      <div className="is-flex is-multiline" style={{ alignItems: "center" }}>
        <div className="h2">
          {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photosR) === "isorel" && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}
          Je désire un fond isorel
          {isMounted ? <ReactTooltip id="fi" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
          <span data-multiline="true" data-for="fi" data-tip="Plaque qui maintient votre sujet dans le cadre">
            <small>
              <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
            </small>
          </span>
        </div>

        <div className="yesno ml-2" style={{ height: "2rem" }}>
          <button
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});dispatch(setIsorel(true, true))}}
            role="radio"
            aria-checked={pageProduit.isorel}
            tabIndex={0}
            className={`button ${pageProduit.isorel ? "selected" : ""}`}
            onKeyPress={() => {}}
          >
            <span>Oui</span>
            <FontAwesomeIcon icon={faCheck} className="ml-2" />
          </button>
          {pageProduit.service !== "coupe_assemble_option" ||
            (props.product.cat_id_originale !== 6 && (
              <button
                disabled={pageProduit.largeur < props.product.ba_mini || pageProduit.hauteur < props.product.ba_mini}
                onClick={(e) => {
                  window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                  if (!(pageProduit.largeur < props.product.ba_mini || pageProduit.hauteur < props.product.ba_mini)) {
                    dispatch(setIsorel(false, true))
                  }
                }}
                role="radio"
                aria-checked={pageProduit.isorel == false}
                tabIndex={0}
                className={`button ml-2 ${pageProduit.isorel == false ? "selected" : ""}`}
                onKeyPress={() => {}}
              >
                <span>Non</span>
                <FontAwesomeIcon icon={faTimes} className="ml-2" />
              </button>
            ))}
        </div>
      </div>
      <div className="is-flex is-flex-direction-row is-align-items-baseline">
        {(product.cat_id_originale === 10 || product.cat_id_originale === 18) && <i>L’isorel permet d’avoir des accroches de suspension à mon cadre et facilite l’accrochage</i>}
      </div>
      {pageProduit.isorel == false ? <div className="notification is-info">
        <b>ATTENTION :</b> si vous ne choisissez pas un fond isorel, l’option <b>verre</b> ou <b>plexiglass</b> ne vous sera pas proposée.
      </div> : null}
    </>
  )
}

ChoixFondIsorel.propTypes = {
  product: PropTypes.object,
  pa: PropTypes.array,
}

export default ChoixFondIsorel
