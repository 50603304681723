import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { useStaticQuery, graphql } from "gatsby"

import AjouterPanier from "../components/ajouterPanier"
import Dimensions from "../components/dimensions"

import Croquis from "./../components/croquis"
import astralgif from "../images/astral.gif"
import cristalgif from "../images/cristal.gif"
import { getSizeInfos, rehausses, woodColors } from "../profcadresprix/index-legacy"
import SEO from "./../components/seo"
import { resetPageProduct, setDimensionsDefault } from "../actions/cadre"

import { setCartonBarriere, setIsorel, mjsService, setProtection } from "../actions/cadre"
import { setPp, setPpType } from "../actions/passe"

import ChoixDoublo from "../components/choix/choixDoublo"
import slugify from "slugify"
import ChoixPassePartout from "../components/choix/choixPassePartout"
import ChoixFondIsorel from "../components/choix/choixFondIsorel"
import ChoixProtection from "../components/choix/choixProtection"
import AjouterPanierEchantillon from "../components/ajouterPanierEchantillon"
import Fade from "react-reveal/Fade"
import ImporterPhoto from "../components/importerPhoto"
import ChoixKitMontage from "../components/choix/ChoixKitMontage"
import ChoixCartonBarriere from "../components/choix/ChoixCartonBarriere"
import { cancelPhoto, setPrevisuPhoto } from "../actions/photos"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearchPlus, faUndoAlt } from "@fortawesome/free-solid-svg-icons"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import { getPrix } from "../services/panier"
import { getOptions, getProtection, getServices } from "@bko/optionsproduits"
import { ChoixRehausse } from "../components/choix/choixRehausse"
import ImgProfil from "../components/img/imgProfil"
import ReactTooltip from "react-tooltip"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ImgForProduct from "../components/img/imgForProduct"
import { PrevisuPrecoupe, PrevisuCadreComplet, PrevisuPasseSeul } from "@bko/previsus"
import { setPpMarginValid, setPpInterL, setPpInterH, setPpMargeB, setPpMargeH, setPpMargeG, setPpMargeD } from "./../actions/passe"
import { setShowEditMarges } from "../actions/aspect"
import { DisplayTirage } from "../components/displayTirage"

const ProductPage = (props) => {
  const [zoomed, setZoomed] = useState(false)
  const { pa } = props.pageContext
  const [product, updateProduct] = useState(props.pageContext.product) // dans le state pour le stock et le continue qui doivent se mettre à jour
  const pageProduitAct = useSelector((s) => s.PageProduit)
  const aspectProduit = useSelector((s) => s.aspectProduit)
  const pageProduit = {
    ...pageProduitAct,
    doublo: !pageProduitAct.doublo ? (product.doublo_defaut !== null ? product.doublo_defaut : null) : pageProduitAct.doublo,
  }
  const divPrevisus1 = useRef()
  const photos = useSelector((s) => s.photosR)
  const dispatch = useDispatch()
  const init = () => {
    if (pageProduit.hauteur === "") {
      dispatch(setDimensionsDefault())
    }

    if (pageProduit.dimensionsDefault) {
      dispatch(setIsorel(null))
      dispatch(setShowEditMarges(false))
    }

    if ([475, 540].includes(product.fa_id)) {
      dispatch(setIsorel(false))
      dispatch(setProtection(null))
      dispatch(setPpType("aucun"))
      dispatch(setCartonBarriere(false))
      dispatch(mjsService("coupe_assemble_option"))
    }

    if ([10, 18].includes(product.cat_id)) {
      dispatch(setCartonBarriere(false))
      dispatch(setIsorel(false))
    }
    if (Object.keys(photos.photos).length >= 1) {
      dispatch(mjsService("coupe_assemble_option"))
      dispatch(setIsorel(true))
    }

    if (product.cat_id === 19 || [475, 540].includes(product.fa_id) || product.cat_id === 10 || product.cat_id === 18) {
      dispatch(setPpType("aucun"))
    }

    dispatch(
      setPp(
        Object.values(props.pageContext.passe_partout)[0].find((e) => e.pa_id === 175),
        true
      )
    )
  }
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    dispatch(resetPageProduct())
    init()
    setIsMounted(true);

    const abortController = new AbortController();
    GetBackendStock(abortController)
    let timer = setInterval(() => GetBackendStock(abortController), 60000)
    return () => {
      clearInterval(timer)
      abortController.abort()
    }
  }, [])

  const GetBackendStock = (abortController) => {
    if (typeof window !== "undefined") {
      var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          action: "getBStock",
          id: product.ba_id,
          category: "baguette",
          from: "product",
        }),
        signal: abortController.signal,
      })
        .then((response) => response.json())
        .then((result) => {
          updateProduct({ ...product, ba_continue: parseInt(result.ba_continue), ba_stock: parseInt(result.stock) })
        })
        .catch(ex => console.error(ex))
    }
  }
  const renderPrevisus = (widthPrevisu) => {
    const { product } = props.pageContext
    const maxWidthPrevisu = typeof window !== "undefined" ? window.innerWidth - 64 - 24 : 1000 // Width du fluid - margin du cadre
    return [540].includes(product.fa_id) ? (
      //<PrevisuAstral largeur={this.state.largeur} hauteur={this.state.hauteur} img={data.mesProduits} />
      <ImgForProduct name={"astral_face"} alt="Cadre astral" />
    ) : pageProduit.service === "precoupe" /*|| pageProduit.service === "precoupe_kit"*/ ? (
      <PrevisuPrecoupe
        baguette={{ ...product, imgLongueur: getImage(product.imgLongueur) }}
        largeurFixe={maxWidthPrevisu < widthPrevisu - 40 ? maxWidthPrevisu : widthPrevisu - 40}
        largeurCadre={pageProduit.hauteur}
        imageComponent={GatsbyImage}
        hauteurCadre={pageProduit.largeur}
        backgroundPouBlanc={product.cog_id === 3 ? "#9F8F7E" : "transparent"}
      />
    ) : (
      <PrevisuCadreComplet
        product={{ ...product, imgLongueur: getImage(product.imgLongueur) }}
        couleurInterieur={product.cog_id === 3 || (pageProduit.doublo && pageProduit.doublo.cog_id) === 3 ? "#9F8F7E" : "#fbf8f1"}
        marginEdit={pageProduit.isorel === true && pageProduit.pp.pp !== null && widthPrevisu == 400}
        largeurFixe={maxWidthPrevisu < widthPrevisu ? maxWidthPrevisu : widthPrevisu}
        hauteurFixe={maxWidthPrevisu < widthPrevisu ? maxWidthPrevisu : widthPrevisu}
        pageProduit={{
          ...pageProduitAct,
          doublo: !pageProduit.doublo ? null : { ...pageProduit.doublo, imgLongueur: getImage(pageProduit.doublo.imgLongueur) },
        }}
        photo={photos.previsuPhoto}
        dispatch={dispatch}
        photos={photos.photos}
        actions={{ setPpMarginValid, setPpInterL, setPpInterH, setPpMargeB, setPpMargeH, setPpMargeG, setPpMargeD }}
        aspectProduit={aspectProduit}
        imageComponent={GatsbyImage}
      />
    )
  }

  const StockMini = (product.ba_mini * 4 * (product.ba_chutes / 100 + 1)) / 100

  const styleImageMontage = {
    width: "325px",
    marginBottom: "15px",
  }

  const familleSansAttaches = [476, 510, 511, 540, 475]

  const d = new Date()
  d.setDate(d.getDate() + 7)
  const { largeurIntra, hauteurIntra, perimetreIntra, fullPerimetre } = getSizeInfos(pageProduit, product, pageProduit.doublo)
  const finalSizeValid =
    hauteurIntra <= product.ba_maxi &&
    largeurIntra <= product.ba_maxi &&
    (perimetreIntra <= product.ba_perimetre_maxi || (pageProduit.service === "precoupe" && perimetreIntra <= 600))
  const width_croquis = typeof window !== "undefined" ? ((window.innerWidth - 64 - 24) / 12) * 2 : 1000
  const doublo = pageProduit.doublo
  const conditions = getOptions({ ...product, cat_id: product.cat_id_originale }, pageProduitAct, photos)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  return (
    <>
      <div className="modal" style={{ display: zoomed ? "block" : "none" }}>
        <div
          className="modal-background"
          onClick={() => setZoomed(false)}
          onKeyPress={() => {}}
          tabIndex={0}
          role="button"
          style={{ backgroundColor: "rgba(168, 168, 168, 0.86)" }}
        ></div>
        <div className="modal-content" style={{ marginTop: "7rem", overflow: "hidden" }}>
          {renderPrevisus(700)}
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setZoomed(false)} data-cy="product-zoom-close"></button>
      </div>
      <Layout banner={false} location={props.location}>
        <SEO
          title={`${props.pageContext.cat} - ${product.fa_libelle}`}
          description={`${product.ba_descriptif} - ${props.pageContext.cat} découpé sur mesure dans notre atelier à Besançon`}
          canonical={`${site.siteMetadata.siteUrl}/${props.pageContext.canonical}`}
          jsonLd={[
            {
              "@context": "http://schema.org",
              "@type": "Product",
              identifier: product.bas_reference,
              description: product.ba_descriptif,
              name: product.fa_libelle,
              sku: product.ba_id,
              url: `${site.siteMetadata.siteUrl}/${props.pageContext.url}`,
              mpn: "B-" + product.ba_id,
              brand: {
                "@type": "Brand",
                name: "Les Professionnels du Cadre",
              },
              offers: {
                "@type": "Offer",
                availability: "http://schema.org/InStock",
                price: getPrix(product, pageProduit, pa, photos).toFixed(2),
                url: `${site.siteMetadata.siteUrl}/${props.pageContext.url}`,
                priceCurrency: "EUR",
                priceValidUntil: d.toISOString().substr(0, 10),
              },
              itemCondition: "https://schema.org/NewCondition",
              image: product.ba_photo_longueur,
              category: props.pageContext.cat,
            },
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: site.siteMetadata.siteUrl,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Cadre sur mesure",
                  item: `${site.siteMetadata.siteUrl}/cadres/`,
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: props.pageContext.cat,
                  item: `${site.siteMetadata.siteUrl}${props.pageContext.catPath}`,
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: `${props.pageContext.cat} - ${product.fa_libelle}`,
                  item: `${site.siteMetadata.siteUrl}/${props.pageContext.url}`,
                },
              ],
            },
          ]}
        />

        <div className="columns is-multiline article">
          <div className="column is-mobile is-two-thirds-tablet is-6-widescreen ">
            <nav className="breadcrumb mb-2 mt-4">
              <ul>
                <li>
                  <Link to="/">Accueil</Link>
                </li>
                <li>
                  <Link to="/cadres/">Cadres sur mesure</Link>
                </li>
                <li>
                  <Link to={props.pageContext.catPath}>{props.pageContext.cat}</Link>
                </li>
                <li>
                  <span className="pl-3">{product.fa_libelle}</span>
                </li>
              </ul>
            </nav>
            <h1 className="mt-0">
              {product.fa_libelle}
              <div>
                Réf.{" "}
                <span className="has-text-weight-bold">
                  {product.bas_reference.toUpperCase()}
                  {pageProduit.doublo ? ` + ${pageProduit.doublo.bas_reference.toUpperCase()}` : ""}
                </span>
              </div>
            </h1>
            <div>
              {pageProduit.service === "precoupe" && product.cat_id === 6
                ? product.ba_descriptif.replace(
                    "mise en place du sujet rapide et simple: il faut enlever les ressorts qui permet de pousser le sujet vers l'avant du cadre et ensuite à l'aide d'un tournevis plat dévisser un coté du cadre tenu par une équerre à vis à chaque angle afin de pouvoir glisser le sujet dans celui-ci et ensuite revisser le coté (encadrement livré monté prêt à l'emploi)",
                    " livré avec son sachet d’accessoires pour un montage rapide et simple du cadre par vos soins"
                  )
                : product.ba_descriptif}{" "}
              {pageProduit.doublo ? " + " + pageProduit.doublo.ba_descriptif.toLocaleLowerCase() : null}
            </div>
            <div className="columns colG mt-4">
              <div className="column is-mobile is-12-tablet is-8-widescreen">
                <div
                  ref={divPrevisus1}
                  className={`previsu ${product.cog_id === 3 || (pageProduit.doublo && pageProduit.doublo.cog_id) === 3 ? "backgroundPouBlanc" : ""}`}
                >
                  {renderPrevisus(typeof divPrevisus1.current !== "undefined" ? divPrevisus1.current.offsetWidth - 100 : 400)}
                  <div className="is-flex is-flex-direction-row-reverse is-align-items-center	mt-2">
                    <button onClick={() => setZoomed(true)} className="button" data-cy="product-zoom-open">
                      <FontAwesomeIcon icon={faSearchPlus} />
                    </button>
                    {["simple", "multivues"].includes(pageProduit.pp.type) && (
                      <div className="ml-4">
                        Dimensions intérieures du cadre :{" "}
                        <span>
                          {parseFloat((product.cat_id === 16 || product.cat_id_originale === 16 ? largeurIntra + 10 : largeurIntra).toFixed(2))} x{" "}
                          {parseFloat((product.cat_id === 16 || product.cat_id_originale === 16 ? hauteurIntra + 10 : hauteurIntra).toFixed(2))}cm
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="columns mt-5 is-vcentered">
                  <div className="column is-7">
                    {Object.keys(photos.photos).length < 1 && ![540].includes(product.fa_id) && pageProduit.service !== "precoupe" ? <ImporterPhoto /> : null}
                  </div>
                  {!pageProduit.dimensionsDefault && (
                    <div className="productPrice is-size-4 has-text-grey has-text-right column is-5">
                      {conditions.ajouterPanier ? "Prix : " : "A partir de "}
                      <span className="has-text-primary has-text-weight-bold">
                        {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                          getPrix(
                            product,
                            {
                              protection: Object.keys(getProtection({ ...product, cat_id: product.cat_id_originale }, pageProduit, photos))[0],
                              ...pageProduit,
                            },
                            pa,
                            photos
                          )
                        )}{" "}
                        TTC
                      </span>
                    </div>
                  )}
                </div>
                {product.fa_id === 540 && (
                  <div className="is-info notification">la prévisualisation de votre tirage dans le cadre n’est pas disponible avec le cadre ASTRAL</div>
                )}
                {conditions.ajouterPanier && (
                  <div className="description mt-5">
                    <b>Description : </b>
                    <div className="columns">
                      <div className="column is-6">
                        Cadre : <span>{product.fa_libelle}</span>
                        <br />
                        {["simple", "multivues"].includes(pageProduit.pp.type) && (
                          <>
                            Dimensions fenêtre image passe partout :{" "}
                            <span>
                              {pageProduit.largeur} x {pageProduit.hauteur}cm
                            </span>
                            <br />
                          </>
                        )}
                        Service : <span>{pageProduit.service !== null ? getServices()[pageProduit.service].title : "-"}</span>
                        <br />
                        Protection : <span>{pageProduit.protection === null ? "-" : getProtection()[pageProduit.protection].title}</span>
                        <br />
                        {product.doublo_dispos.length >= 1 && (
                          <>
                            Doublo : <span>{pageProduit.doublo ? "Oui" : "Non"}</span>
                            <br />
                          </>
                        )}
                        {["simple", "multivues"].includes(pageProduit.pp.type) ? (
                          <>
                            Marges haut - bas:{" "}
                            <span>
                              {pageProduit.pp.margeH} cm - {pageProduit.pp.margeB} cm
                            </span>
                            <br />
                            Marge gauche - droite :{" "}
                            <span>
                              {pageProduit.pp.margeG} cm - {pageProduit.pp.margeD} cm
                            </span>
                            <br />
                          </>
                        ) : null}
                        {Object.keys(photos.photos).length < 1 && (
                          <>
                            Dimensions intérieures du cadre :{" "}
                            <span>
                              {parseFloat((product.cat_id === 16 || product.cat_id_originale === 16 ? largeurIntra + 10 : largeurIntra).toFixed(2))} x{" "}
                              {parseFloat((product.cat_id === 16 || product.cat_id_originale === 16 ? hauteurIntra + 10 : hauteurIntra).toFixed(2))}cm
                            </span>
                            <br />
                          </>
                        )}
                        {product.cat_id === 1 ? (
                          <>
                            Réhausse :{" "}
                            <span>{pageProduit.rehausse === null ? "aucune " : rehausses[pageProduit.rehausse].title.replace("Réhausse", "oui, ")}</span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="column is-6">
                        Couleur : <span>{product.ba_couleur_precise || product.co_libelle}</span>
                        <br />
                        Isorel : <span>{pageProduit.isorel === null ? "-" : pageProduit.isorel ? "Oui" : "Non"}</span>
                        <br />
                        {Object.keys(photos.photos).length >= 1 && (
                          <>
                            Tirage photo : <span>oui</span>
                            <br />
                          </>
                        )}
                        Type passe partout :{" "}
                        <span style={{ textTransform: pageProduit.pp.type === "simple" ? "none" : "capitalize" }}>
                          {pageProduit.pp.type === null ? "-" : pageProduit.pp.type === "simple" ? "Une ouverture" : pageProduit.pp.type}
                        </span>
                        <br />
                        Passe partout :{" "}
                        <span>
                          {[null, "aucun"].includes(pageProduit.pp.type) ||
                          pageProduit.pp.pp == undefined ||
                          pageProduit.pp.pp === null ||
                          pageProduit.pp.pp.pa_libelle == undefined
                            ? "-"
                            : pageProduit.pp.pp.pa_libelle.replace(new RegExp(/(Passe-partout)/gi), "")}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="column is-mobile is-two-thirds-tablet is-4-widescreen ">
                <div className="croquis">
                  {pageProduit.service === "astral" && pageProduit.largeur !== pageProduit.hauteur ? (
                    ""
                  ) : (
                    <Croquis
                      url={
                        pageProduit.rehausse == 12
                          ? rehausses[pageProduit.rehausse].croquis
                          : product.croquis_svg !== null
                          ? product.croquis_svg
                          : product.croquis
                      }
                      feuillure={!doublo ? product.ba_hauteur_feuillure : doublo.ba_hauteur_feuillure}
                      product={product}
                      largeurFixe={width_croquis - 50}
                      tailleCote={50}
                      rehausse={pageProduit.rehausse}
                      doublo={doublo}
                      showHauteurFeuillure={![6].includes(product.cat_id)}
                    />
                  )}
                </div>

                <div>
                  {/* croquis de montage caisse américaine */}
                  {product.photo_montage !== null && product.photo_montage[product.photo_montage.length - 1] !== "/" ? <img style={styleImageMontage} src={product.photo_montage} alt="photo_montage" /> : ""}
                </div>
                <div>
                  {/* photo des attaches */}
                  {["coupe_assemble_option"].includes(pageProduit.service) &&
                  pageProduit.isorel &&
                  !familleSansAttaches.includes(product.fa_id) &&
                  ![6, 10].includes(product.cat_id_originale) &&
                  (![20, 19, 3, 18].includes(product.cat_id_originale) || pageProduit.isorel === true) ? (
                    <div className="mt-5 is-flex is-justify-content-center">
                      <ImgForProduct name={"img1"} alt="photo des attaches" style={{ width: "10rem", height: "10rem" }} />
                    </div>
                  ) : (
                    ""
                  )}
                  {pageProduit.isorel && [476, 411].includes(product.fa_id) ? (
                    <div className="mt-5 is-flex is-justify-content-center">
                      <ImgForProduct name={"aluca"} alt="photo des attaches aluca" style={{ width: "10rem", height: "10rem" }} />
                    </div>
                  ) : (
                    ""
                  )}
                  {product.cat_id_originale == 1 && (
                    <>
                      <Zoom zoomMargin={150} zoomZindex={99} wrapStyle={{ display: "block", marginTop: "1rem" }}>
                        <ImgForProduct name="vitrine" alt="vitrine" />
                      </Zoom>
                      {pageProduit.rehausse !== null && (
                        <Zoom zoomMargin={150} zoomZindex={99} wrapStyle={{ display: "block", marginTop: "1rem" }}>
                          <ImgForProduct name="vitrine_montage" alt="vitrine montage" />
                        </Zoom>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {[540].includes(product.fa_id) ? (
                    <>
                      <Zoom zoomMargin={80}>
                        <ImgForProduct name={"astral_zoom"} alt="photo des attaches" style={{ width: "150px", margin: "0 auto" }} />
                      </Zoom>
                      <Zoom zoomMargin={80}>
                        <ImgForProduct name={"astral_face2"} alt="photo des attaches" style={{ width: "150px", margin: "0 auto" }} />
                      </Zoom>
                      <center>Image d&apos;illustration pour exemple</center>
                      <Zoom zoomMargin={150}>
                        <ImgForProduct name={"astral_arriere"} alt="photo arrière cadre astral" style={{ width: "200px", margin: "10px auto" }} />
                      </Zoom>
                      <img src={astralgif} alt="NOUVEAUTÉ" style={{ width: 350, minHeight: 320 }} />
                    </>
                  ) : (
                    ""
                  )}
                  {[475].includes(product.fa_id) && (
                    <>
                      <img src={cristalgif} alt="NOUVEAUTÉ" style={{ width: 350, minHeight: 320 }} />
                      <center>Image d&apos;illustration pour exemple</center>
                    </>
                  )}
                </div>

                {product.nielsen ? (
                  <div className="mt-5 is-flex is-justify-content-center">
                    <ImgProfil profil={product.fa_libelle} style={{ width: 150 }} />
                  </div>
                ) : (
                  ""
                )}

                <div className="h3" style={{ marginTop: "1.5rem" }}>Couleurs disponibles</div>

                <div className="columns is-mobile" style={{ padding: "1rem", flexFlow: "row wrap" }}>
                  {props.pageContext.otherColors.map((node, index) => (
                    <Link
                      className={`column ${node.ba_continue !== 2 ? "" : "ruptureMini"} ${product.nielsen ? "is-6" : "is-4"} ${
                        product.ba_id == node.ba_id ? "couleurSelected" : ""
                      }`}
                      key={index}
                      style={{ height: "70px", cursor: "pointer" }}
                      data-for="colors"
                      data-tip={
                        typeof woodColors.find((e) => e.id.includes(node.ba_id)) === "undefined"
                          ? (node.ba_couleur_precise || node.co_libelle)
                          : woodColors.find((e) => e.id.includes(node.ba_id)).title
                      }
                      to={`/cadre/${slugify(`${node.cat_libelle}-${node.fa_libelle}-${node.co_libelle}-${node.ba_id}`)}/`}
                    >
                      {product.nielsen ? (
                        <ImgProfil id={node.ba_id} imgStyle={{ objectFit: "fill" }} style={{ height: 30 }} />
                      ) : (
                        <GatsbyImage className="couleurShadow" style={{ height: "100%", width: "100%" }} image={getImage(node.imgCoin)} alt={"Baguette1"} />
                      )}
                    </Link>
                  ))}
                  {isMounted ? <ReactTooltip effect="solid" id="colors" /> : null}
                </div>

                <div>
                  {[475].includes(product.fa_id) ? (
                    <>
                      <Zoom zoomMargin={80}>
                        <ImgForProduct name={"cristal_face"} alt="photo avant cadre entre 2 verres" style={{ width: "200px", margin: "0 auto" }} />
                      </Zoom>
                      <Zoom zoomMargin={150}>
                        <ImgForProduct name={"cristal_arriere"} alt="photo arrière cadre entre 2 verres" style={{ width: "200px", margin: "10px auto" }} />
                      </Zoom>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="column is-mobile is-12-tablet is-6-widescreen pt-5 mt-5">
            {conditions.resetButton && (
              <div className="is-flex is-flex-direction-row-reverse">
                <button
                  className="button is-secondary is-small is-warning"
                  onClick={() => {
                    dispatch(setPpType(null))
                    dispatch(setIsorel(null))
                    dispatch(resetPageProduct())
                    dispatch(setPrevisuPhoto(null))
                    dispatch(setDimensionsDefault())
                    init()
                  }}
                  data-cy="product-reinit"
                >
                  <FontAwesomeIcon icon={faUndoAlt} className="mr-1" />
                  Réinitialiser la configuration du cadre
                </button>
              </div>
            )}
            {product.ba_stock < StockMini && product.ba_continue !== 5 ? (
              <div className="notification is-info is-light mt-1">Cette baguette est en rupture de stock</div>
            ) : (
              <>
                {Object.keys(photos.photos).length >= 1 ? (
                  <div className="notification is-info is-light">
                    vous avez choisi un tirage photo {photos.tirage !== "tirage_sublimation" ? "avec un passe partout" : ""}, les dimensions sans le cadre sont
                    de {pageProduit.largeur}x{pageProduit.hauteur}cm
                    <div className="mt-2">
                      <DisplayTirage cols={pageProduit.pp.ouvertures[0]} rows={pageProduit.pp.ouvertures[1]} />
                    </div>
                    <div className="flexRight mt-2">
                      <Link to={"/tirage-photo-encadre/"} style={{ textDecoration: "none" }}>
                        <button className="button" data-cy="product-modifie-tirage">
                          Modifier
                        </button>
                      </Link>
                      <button className="button mr-2" onClick={() => dispatch(cancelPhoto())} to={`/cadres/`} data-cy="product-annule-tirage">
                        Annuler service image encadrée
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <Dimensions
                      ba_max={product.ba_maxi}
                      ba_min={product.ba_mini}
                      ba_perimetre_maxi={product.ba_perimetre_maxi}
                      product={product}
                      ba_chutes={product.ba_chutes}
                      famille={product.fa_id}
                      init={init}
                    />
                    {perimetreIntra > product.ba_perimetre_maxi && (
                      <div className="notification is-info">
                        la section du cadre ne permet pas d’avoir une telle dimension : le périmètre maximum pour ce cadre est {product.ba_perimetre_maxi}cm.
                        {Object.keys(getServices({ ...product, cat_id: product.cat_id_originale }, pageProduit, photos)).includes("precoupe") && (
                          <> Seul le service pré-coupé peut vous être proposé.</>
                        )}
                      </div>
                    )}
                  </>
                )}
                {conditions.doublo && (
                  <Fade bottom>
                    <ChoixDoublo
                      baguettes={product.doublo_dispos}
                      baguette_ext={product}
                      service={pageProduit.service}
                      produits_annexes={pa}
                      enableCategs={product.cat_id_originale == 20}
                    />
                  </Fade>
                )}
                {conditions.isorel && (
                  <Fade bottom>
                    <ChoixFondIsorel product={product} pa={pa} />
                  </Fade>
                )}
                {conditions.passePartout && (
                  <Fade bottom>
                    <ChoixPassePartout passe_partout={props.pageContext.passe_partout} product={product} pa={pa} />
                  </Fade>
                )}
                {!finalSizeValid ? (
                  <div className="notification is-danger mt-5">
                    Les dimensions demandées excèdent la longueur maximum de coupe pour cette baguette qui est de {product.ba_maxi}cm ou le périmètre maximum de{" "}
                    {product.ba_perimetre_maxi}cm
                  </div>
                ) : (
                  <>
                    {conditions.cartonBarriere && (
                      <Fade bottom>
                        <ChoixCartonBarriere product={product} pa={pa} />
                      </Fade>
                    )}
                    {conditions.rehausse && (
                      <Fade bottom>
                        <ChoixRehausse baguette_ext={product} produits_annexes={pa} rehausses={props.pageContext.rehausses} />
                      </Fade>
                    )}
                    {conditions.protection ? (
                      <Fade bottom>
                        <ChoixProtection pa={pa} product={product} />
                      </Fade>
                    ) : null}
                  </>
                )}
                {conditions.kitMontage && product.fa_kit_montage && (
                  <Fade bottom>
                    <ChoixKitMontage pa={pa} product={product} />
                  </Fade>
                ) || null}
                {[1, 5, 0].includes(product.ba_continue) && (product.ba_continue !== 0 || fullPerimetre / 100 <= product.ba_stock) ? (
                  <div className="has-text-right">
                    <AjouterPanier pa={pa} greyed={!conditions.ajouterPanier} product={product} />
                  </div>
                ) : (
                  <div style={{ textAlign: "right" }}>
                    <div className="tag is-danger" style={{ fontSize: "1.1em" }}>
                      Baguette en rupture, en cours de réapprovisionnement
                    </div>
                  </div>
                )}
                {product.ba_stock >= StockMini && product.nielsen === 0 && (
                  <div className="echantillon is-centered columns is-multiline mt-5">
                    <div className="column is-narrow ">
                      <AjouterPanierEchantillon pa={pa} product={product} />
                    </div>
                    {pageProduit.pp.pp !== null && ["simple", "multivues"].includes(pageProduit.pp.type) && !pageProduit.pp.default ? (
                      <fieldset
                        className="mt-4 column is-narrow  "
                        style={{
                          border: "2px solid #FFDC4A",
                          padding: "0.5rem 1rem",
                          margin: "1rem",
                        }}
                      >
                        <legend
                          style={{
                            textAlign: "left",
                            marginLeft: 5,
                            padding: 10,
                            fontSize: "1.2em",
                            fontWeight: "bold",
                            color: "#666",
                          }}
                        >
                          Commander un échantillon de passe-partout
                        </legend>
                        <div className="columns">
                          <div className="column is-narrow">
                            <PrevisuPasseSeul
                              product={pageProduit.pp.pp}
                              pageProduit={{
                                largeur: 20,
                                hauteur: 20,
                                pp: { ouvertures: [1, 1], type: "simple", margeH: 5, margeB: 5, margeD: 5, margeG: 5, interL: 0, interH: 0 },
                              }}
                              size={80}
                            />
                          </div>
                          <div className="column">
                            <p style={{ fontSize: "1em", color: "#888", margin: 0 }}>
                              Longueur : 20cm - Tarif :{" "}
                              {new Intl.NumberFormat("fr-FR", {
                                style: "currency",
                                currency: "EUR",
                              }).format(pageProduit.pp.pp.prixEchantillon)}{" "}
                              TTC
                              <br />
                              Frais de ports <b>offerts</b> !
                            </p>
                            <AjouterPanier pa={props.pageContext.pa} product={pageProduit.pp.pp} echantillon={true} />
                          </div>
                        </div>
                      </fieldset>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

ProductPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default ProductPage
