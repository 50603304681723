import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { setIsorel } from "../../actions/cadre"
import { setPpMarginValid, setPp, setPpMargeB, setPpMargeD, setPpMargeG, setPpMargeH, setPpType } from "../../actions/passe"
import ChoixMultivues from "./choixMultivues"
import { getSizeInfos } from "../../profcadresprix/index-legacy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { useSelector, useDispatch } from "react-redux"
import { bordureMin, orderPp } from "../../services/prixBaguettes"
import { useStaticQuery, graphql } from "gatsby"
import { renderToString } from "react-dom/server"
import { getEtape } from "@bko/optionsproduits"
import { setAspectMarges } from "../../actions/aspect"
import { getPrix } from "../../services/panier"
import { getImage } from "gatsby-plugin-image"

export const ChoixPassePartout = (props) => {
  const upperCaseFirst = (s) => s.charAt(0).toUpperCase() + s.substr(1)
  const pageProduit = useSelector((state) => state.PageProduit)
  const aspectProduit = useSelector((state) => state.aspectProduit)
  const photos = useSelector((state) => state.photosR)
  const pp = pageProduit.pp
  const dispatch = useDispatch()
  const { hauteurIntra, largeurIntra } = getSizeInfos(pageProduit, props.product, pageProduit.doublo)
  let passes = { ...props.passe_partout }
  Object.keys(passes).forEach(
    (grp) =>
      (passes[grp] = passes[grp]
        .filter((p) => props.product.cat_id !== 20 || (p.pa_reference && !p.pa_reference.includes("+") && ![20, 19].includes(p.pat_id)))
        .map((p) => {
          return (hauteurIntra < p.pa_plaque_longueur && largeurIntra < p.pa_plaque_largeur) ||
            (largeurIntra < p.pa_plaque_longueur && hauteurIntra < p.pa_plaque_largeur)
            ? p
            : p.gm !== null &&
              ((hauteurIntra < p.gm.pa_plaque_longueur && largeurIntra < p.gm.pa_plaque_largeur) ||
                (largeurIntra < p.gm.pa_plaque_longueur && hauteurIntra < p.gm.pa_plaque_largeur))
            ? p.gm
            : null
        })
        .filter((p) => p !== null))
  )
  const images = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativePath: { regex: "/^passe//" } }) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)

  const hauteurCm = bordureMin + bordureMin + pageProduit.hauteur * pageProduit.pp.ouvertures[1] + bordureMin * (pageProduit.pp.ouvertures[1] - 1)
  const largeurCm = pageProduit.largeur * pageProduit.pp.ouvertures[0] + (bordureMin + bordureMin) + bordureMin * (pageProduit.pp.ouvertures[0] - 1)

  const ppAvailable =
    hauteurCm <= props.product.ba_maxi &&
    largeurCm <= props.product.ba_maxi &&
    hauteurCm * 2 + largeurCm * 2 <= props.product.ba_perimetre_maxi &&
    Object.keys(passes).some((e) => passes[e].length >= 1) &&
    !(Math.min(parseFloat(pageProduit.largeur), parseFloat(pageProduit.hauteur)) > 90 || Math.max(parseFloat(pageProduit.largeur), parseFloat(pageProduit.hauteur)) > 140)
    const {product} = props
    const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  useEffect(() => {
    if (!ppAvailable && pageProduit.pp.type !== "aucun") {
      dispatch(setPpType("aucun"))
    }
  },[ppAvailable, pageProduit.pp.type]);

  return (
    <>
      {Object.keys(photos.photos).length == 0 && (
        <>
          <div className="is-flex is-multiline" style={{alignItems: "center"}}>
            <div className="h2">
              {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photos) === "passePartout" && pp.type === null && (
                <FontAwesomeIcon icon={faHandPointRight} className="cursor" />
              )}
              Je désire un passe-partout
              {isMounted ? <ReactTooltip id="passe" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
              <span
                data-multiline="true"
                data-for="passe"
                data-tip="Un passe-partout est un encadrement en carton proposé sur une oeuvre d’art ou une reproduction.<br /> La fonction première du passe-partout
                est de créer un espace entre l’oeuvre et le verre de l’encadrement de façon à protèger l’oeuvre d’un contact direct.<br /> Il assure également une
                fonction décorative."
              >
                <span className="not-active">
                  <small>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                  </small>
                </span>
              </span>
            </div>
            <div className="columns choix_passepartout ml-3" style={{ height: "2rem" }}>
              {ppAvailable && (
                <ChoixMultivues
                  text="&nbsp;Oui"
                  page="choixPassePartout"
                  hauteur={pageProduit.hauteur}
                  largeur={pageProduit.largeur}
                  sizesMax={{ ba_maxi: props.product.ba_maxi, ba_perimetre_maxi: props.product.ba_perimetre_maxi }}
                  baMini={props.product.ba_mini}
                  disableNone={true}
                  hideNoneMessage={pageProduit.largeur >= props.product.ba_mini && pageProduit.hauteur > props.product.ba_mini}
                  onClick={(e) => window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"})}
                />
              )}
              &nbsp;
              <button
                disabled={pageProduit.largeur < props.product.ba_mini || pageProduit.hauteur < props.product.ba_mini}
                className={`button ${pageProduit.pp.type === "aucun" ? "is-primary" : ""}`}
                onClick={(e) => {
                  window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                  dispatch(setPpType("aucun"))
                }}
                data-cy="passe-no"
              >
                Non
              </button>
            </div>
          </div>
          {!ppAvailable && <div className="notification is-info">Les dimensions saisies ne sont pas compatibles avec un passe partout.</div>}
        </>
      )}
      {["simple", "multivues"].includes(pp.type) && (
        <div>
          <div className="h2">
            {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photos) === "passePartout" && !aspectProduit.marges && "currentStep" && (
              <FontAwesomeIcon className="cursor" icon={faHandPointRight} />
            )}
            Je valide ci-dessous les marges de mon passe partout
          </div>
          <form>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <div className="field has-addons mr-3">
                <div className="control">
                  <span className="button is-static">Haut</span>
                </div>
                <div className="control">
                  <input
                    className="input input-number"
                    type="number"
                    min={0}
                    max={150}
                    defaultValue="5"
                    onBlur={(e) => dispatch(setPpMargeH(parseFloat(e.target.value)))}
                    step="0.1"
                  />
                </div>
                <div className="control">
                  <span className="button is-static">cm</span>
                </div>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
                <div className="field has-addons mr-3">
                  <div className="control">
                    <span className="button is-static">Gauche</span>
                  </div>
                  <div className="control">
                    <input
                      className="input input-number"
                      type="number"
                      min={0}
                      max={150}
                      defaultValue="5"
                      onBlur={(e) => dispatch(setPpMargeG(parseFloat(e.target.value)))}
                      step="0.1"
                    />
                  </div>
                  <div className="control">
                    <span className="button is-static">cm</span>
                  </div>
                </div>
                <div className="field has-addons mr-3">
                  <div className="control">
                    <span className="button is-static">Droite</span>
                  </div>
                  <div className="control">
                    <input
                      className="input input-number"
                      type="number"
                      min={0}
                      max={150}
                      defaultValue="5"
                      onBlur={(e) => dispatch(setPpMargeD(parseFloat(e.target.value)))}
                      step="0.1"
                    />
                  </div>
                  <div className="control">
                    <span className="button is-static">cm</span>
                  </div>
                </div>
              </div>
              <div className="field has-addons mr-3">
                <div className="control">
                  <span className="button is-static">Bas</span>
                </div>
                <div className="control">
                  <input
                    className="input input-number"
                    type="number"
                    min={0}
                    max={150}
                    defaultValue="5"
                    onBlur={(e) => dispatch(setPpMargeB(parseFloat(e.target.value)))}
                    step="0.1"
                  />
                </div>
                <div className="control">
                  <span className="button is-static">cm</span>
                </div>
              </div>
            </div>
          </form>
          {aspectProduit.ppMarginValid && (
            <div className="has-text-centered">
              <button className="button ml-2 mt-2 is-primary" onClick={(e) => {
                if (hauteurIntra < 15 || largeurIntra < 15) {
                  alert('Les dimensions intérieures du cadre ne peuvent pas être en dessous de 15cm, veuillez augmenter les marges du passe partout.')
                } else if ((pageProduit.pp.margeH < bordureMin && pageProduit.pp.margeH != 0) || (pageProduit.pp.margeB < bordureMin && pageProduit.pp.margeB != 0)
                  || (pageProduit.pp.margeG < bordureMin && pageProduit.pp.margeG != 0) || (pageProduit.pp.margeD < bordureMin && pageProduit.pp.margeD != 0)
                  || pageProduit.pp.margeH > 150 || pageProduit.pp.margeB > 150 || pageProduit.pp.margeB > 150 || pageProduit.pp.margeD > 150) {
                  dispatch(setPpMarginValid(false))
                } else {
                  window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                  dispatch(setAspectMarges(true))
                }
              }} data-cy="valider-marges-passe">
                Valider
              </button>
            </div>
          )}

          {!aspectProduit.ppMarginValid && <div className="notification is-danger mt-4">Les marges du passe partout doivent être {bordureMin}cm au minimum et 150 au maximum</div>}
        </div>
      )}
      {aspectProduit.marges && ["simple", "multivues"].includes(pp.type) ? (
        <>
          <div className="h2 pb-3">
            {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photos) === "passePartout" && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}
            Je choisis la couleur et le type du passe partout{" "}
          </div>

          {isMounted ? <ReactTooltip id="pp" type="info" effect="solid" /> : null}
          {Object.keys(passes)
            .filter((e) => passes[e].length >= 1)
            .sort((a, b) => orderPp[a] - orderPp[b])
            .map((e) => (
              <div className="choix_passepartout pl-3 pr-2" key={e}>
                <div className="columns">
                  <div style={{ lineHeight: "0.90em", marginTop: "0.1rem" }}>
                    {upperCaseFirst(
                      e
                        .replace("Double", "2x")
                        .replace("conservation", "qualité conservation")
                        .replace(new RegExp(/(80x102cm|80x120cm|passe partout :|épaisseur (1,4|3,3|1,7|1,5|2.5)mm)/gi), "")
                        .trim()
                    )}
                    <br />
                    <small style={{ fontSize: "0.8em", color: "#888" }}>
                      <i>
                        {upperCaseFirst(
                          e

                            .replace(
                              new RegExp(
                                /(80x102cm|80x120cm|passe partout :|Âme|Double|blanche|blanc|extra|cassé|pigmentée|conservation|teintée dans la masse|noire|couleur|crème)/gi
                              ),
                              ""
                            )
                            .trim()
                        )}
                      </i>
                    </small>
                  </div>
                  <div style={{ minHeight: 50, marginLeft: "0.5rem" }}>
                    {Object.values(
                      passes[e]
                        .sort((a, b) => a.pa_reference - b.pa_reference)
                        .reduce((acc, cur) => {
                          if (cur.pa_reference && cur.pa_reference.includes("+")) {
                            if (typeof acc[cur.pa_reference.split("+")[0]] === "undefined") {
                              acc[cur.pa_reference.split("+")[0]] = []
                            }
                            acc[cur.pa_reference.split("+")[0]].push(cur)
                          } else {
                            if (typeof acc[0] === "undefined") {
                              acc[0] = []
                            }
                            acc[0].push(cur)
                          }
                          return acc
                        }, [])
                    ).map((i, k) => (
                      <div key={k}>
                        {i
                          .sort((a, b) => a.copp_order - b.copp_order)
                          .map((e) => {
                            let image = images.allFile.nodes.find((m) => m.name === e.pa_reference.toLocaleLowerCase())
                            let pageProduitCopy = typeof pageProduit === "object" ? pageProduit : JSON.parse(JSON.stringify(pageProduit));
                            return (
                              <div
                                key={e.pa_reference}
                                onKeyPress={() => {}}
                                role="button"
                                tabIndex={0}
                                style={{ background: e.pa_passe_hexa }}
                                onClick={(el) => {
                                  window.scroll({top: el.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                                  dispatch(setIsorel(true))
                                  dispatch(setPp(e))
                                }}
                                data-for="pp"
                                data-html={true}
                                data-tip={renderToString(
                                  <div className="has-text-centered">
                                    <img
                                      style={{ height: 150, width: 150 }}
                                      alt="passe"
                                      src={typeof image !== "undefined" ? getImage(image.childImageSharp).images.fallback.src : null}
                                    />
                                    <br />
                                    {e.doubles !== null && e.doubles.length >= 1 ? "double" : ""}
                                    {e.pa_libelle.replace(
                                      new RegExp(/(Passe-partout|épaisseur (1.4|1.5|2.5|1.7|3.3)mm|âme (blanche|teintée dans la masse|noire),|,)/gi),
                                      ""
                                    )}
                                    (+
                                    {new Intl.NumberFormat("fr-FR", {
                                      style: "currency",
                                      currency: "EUR",
                                    }).format(getPrix(e, { ...pageProduitCopy, pp: { ...pageProduitCopy.pp, pp: e } }, props.pa, null))}
                                    )
                                  </div>
                                )}
                                className={`pp_couleur disableOutline ${
                                  !pageProduit?.pp.default &&
                                  pageProduit?.pp?.pp !== null &&
                                  pageProduit?.pp?.pp !== undefined &&
                                  pageProduit?.pp?.pp?.pa_reference === e.pa_reference
                                    ? "pp_couleur_active"
                                    : ""
                                }`}
                              >
                                <div className="pp_filet" style={{ border: `3px solid ${e.pa_passe_ame_hexa}` }}></div>
                                <div className="pp_double_color" style={{ background: e.pad_passe_hexa }}></div>
                              </div>
                            )
                          })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : null}
    </>
  )
}

ChoixPassePartout.propTypes = {
  passe_partout: PropTypes.object,
  product: PropTypes.object,
  pa: PropTypes.object,
}

export default ChoixPassePartout
