import React from "react"
import PropTypes from "prop-types"
import AjouterPanier from "../components/ajouterPanier"
import { useSelector } from "react-redux"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const AjouterPanierEchantillon = props => {
  const pageProduit = useSelector(s => s.PageProduit)
  let product = props.product
  let doublo = !pageProduit.doublo ? { ba_largeur: 0 } : pageProduit.doublo

  if (product.doublo_defaut && !pageProduit.doublo) return null;

  return (
    <fieldset
      className="is-narrow mt-3"
      style={{
        border: "2px solid #FFDC4A",
        padding: "1rem 2rem",
      }}
    >
      <legend
        style={{
          textAlign: "left",
          marginLeft: 5,
          padding: 10,
          fontWeight: "bold",
          fontSize: "1.2em",
          color: "#666",
        }}
      >
        Commander un échantillon de {pageProduit.doublo ? "doublo" : "baguette"}
      </legend>
      <div className="columns">
        <div className="column is-narrow">
          <div style={{ width: 80, height: 50, overflow: "hidden", position: "relative" }}>
            <GatsbyImage
              className="couleurShadow"
              style={{
                height: (props.product.ba_largeur * 50) / (doublo.ba_largeur + props.product.ba_largeur),
                left: 0,
                width: "100%",
                position: "absolute",
                top: 0,
              }}
              image={getImage(props.product.imgCoin)}
              alt={"Baguette1"}
            />
            {pageProduit.doublo ? (
              <GatsbyImage
                className="couleurShadow"
                style={{
                  top: (props.product.ba_largeur * 50) / (doublo.ba_largeur + props.product.ba_largeur),
                  position: "absolute",
                  height: (doublo.ba_largeur * 50) / (doublo.ba_largeur + props.product.ba_largeur),
                  width: "100%",
                }}
                id={pageProduit.doublo.imgLongueur}
                alt={"Baguette1"}
              />
            ) : null}
          </div>
        </div>
        <div className="column">
          <p style={{ fontSize: "1em", color: "#888", margin: 0 }}>
            Longueur : 20cm - Tarif :{" "}
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(product.prixEchantillon)}{" "}
            TTC
            <br />
            Frais de ports <b>offerts</b> !
          </p>
          <AjouterPanier pa={props.pa} product={product} echantillon={true} />
        </div>
      </div>
    </fieldset>
  )
}

AjouterPanierEchantillon.propTypes = {
  pa: PropTypes.array,
  product: PropTypes.object,
}

export default AjouterPanierEchantillon
