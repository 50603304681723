import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { setProtection } from "../../actions/cadre"
import { useDispatch, useSelector } from "react-redux"
import { getEtape, getProtection } from "@bko/optionsproduits"
import { getPrix } from "../../services/panier"

export const ChoixProtection = (props) => {
  const pageProduit = useSelector((state) => state.PageProduit)
  const photosR = useSelector((state) => state.photosR)
  const protections = getProtection({ ...props.product, cat_id: props.product.cat_id_originale }, pageProduit, photosR)
  const dispatch = useDispatch()
  const {product} = props
  const textInfoMusee =
    '<div style="text-align:left; font-weight:normal;"><b>Avantages du verre qualité musée :</b><ul><li>- réduit fortement les reflets</li><li>- respecte les couleurs de votre sujet sans déformation contrairement au verre anti-reflet</li><li>- protège votre sujet des UV à hauteur de 70%</li></ul>Ce verre est apprécié par les photographes et les musées mais aussi de clients qui exigent une grande qualité de verre</div>'
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);
  
  return (
    <>
      <div className="h2">
        {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photosR) === "protection" && <FontAwesomeIcon icon={faHandPointRight} className="cursor" />}
        Je choisis une protection (verre ou plexi)
      </div>
      {Object.keys(protections).length === 0 && <div className="notification is-info">Aucune protection n&apos;est disponible.</div>}
      <div className="choix_protection">
        {Object.keys(protections)
          .sort((a, b) => protections[a].order - protections[b].order)
          .map((protection_id, index) => (
            <div
              key={index}
              style={{ cursor: "pointer" }}
              value={protection_id}
              tabIndex={0}
              onKeyPress={() => {}}
              role="radio"
              className={`${pageProduit.protection === protection_id ? "selected" : null}`}
              aria-checked={pageProduit.protection === protection_id}
              onClick={(e) => {
                window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                dispatch(setProtection(protection_id))
              }}
            >
              {protections[protection_id].title}
              {protection_id === "verre_invisible" || protection_id === "double_verre_invisible" ? (
                <span data-for="custom-event" data-html={true} data-tip={textInfoMusee} data-event="click focus">
                  <span className="not-active">
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                  </span>

                  {isMounted ? <ReactTooltip id="custom-event" place="bottom" type="info" globalEventOff="click" /> : null}
                </span>
              ) : null}

              <div className="price">
                {protection_id !== "aucune" ? "+ " : ""}
                {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                  getPrix(props.product, { ...pageProduit, protection: protection_id }, props.pa, photosR) -
                    getPrix(props.product, { ...pageProduit, protection: "aucune" }, props.pa, photosR)
                )}{" "}
                TTC
              </div>
            </div>
          ))}
      </div>
      {pageProduit?.pp?.pp !== null && ([18, 22].includes(pageProduit?.pp?.pp?.pat_id) || (pageProduit?.pp?.pp?.pa_reference && pageProduit?.pp?.pp?.pa_reference.includes("+"))) && (
        <div className="notification is-info mt-2">
          Les verres et plexiglas avec traitement anti reflet ne sont pas proposés avec des passe partout épais, pour des raisons esthétiques
        </div>
      )}
    </>
  )
}

ChoixProtection.propTypes = {
  product: PropTypes.object,
  pa: PropTypes.array,
}

export default ChoixProtection
