import React from "react"
import PropTypes from "prop-types"
import rehausse from "./../images/rehausse.svg"

export const Croquis = props => {
  const { largeurFixe, tailleCote, hauteurMax, doublo, product } = props
  const paddingCotes = 2
  const couleurCotes = "#cc0331"
  //Ratio entre la taille réelle des profils et la largeur désirée de l'image finale
  let ratio = (largeurFixe - tailleCote) / (product.ba_largeur + (doublo ? doublo.ba_largeur : 0))
  if (product.ba_hauteur * ratio > hauteurMax) {
    ratio = hauteurMax / product.ba_hauteur
  }
  //Calcul des largeurs des images rapportées au ratio
  const largeur = (product.ba_embase === null ? product.ba_largeur : product.ba_embase) * ratio
  const hauteur = product.ba_hauteur * ratio

  const largeurCM =
    doublo
      ? doublo.ba_talon + (product.ba_embase === null ? product.ba_largeur : product.ba_embase)
      : product.ba_embase === null
      ? product.ba_largeur
      : product.ba_embase

  const epaisseurCM = doublo ? product.ba_hauteur - product.ba_hauteur_feuillure + doublo.ba_hauteur : product.ba_hauteur
  //Styles
  const styleCroquis = {
    height: hauteur + tailleCote,
    marginBottom: 5,
    marginTop: 8,
  }

  const styleImage = {
    left: tailleCote,
    top: tailleCote,
    width: largeur,
    height: hauteur,
    position: "absolute",
    marginBottom: "3.2rem",
  }

  const styleLargeurCote = {
    left: tailleCote,
    top: "0px",
    width: largeurCM * ratio,
    height: tailleCote,
    lineHeight: tailleCote + "px",
    position: "absolute",
    borderStyle: "dashed solid none solid",
    borderWidth: "1px",
    borderColor: couleurCotes,
    textAlign: "center",
    overflow: "hidden",
  }

  const styleHauteurCote = {
    top: tailleCote,
    width: tailleCote,
    height: epaisseurCM * ratio,
    lineHeight: hauteur + "px",
    position: "absolute",
    borderStyle: "solid none solid dashed",
    borderWidth: "1px",
    borderColor: couleurCotes,
    textAlign: "center",
    overflow: "hidden",
  }
  /*
        const styleFeuillureCote = {
            left: tailleCote + ancrageX,
            top: hauteur - ancrageY + tailleCote,
            width: tailleCote,
            height: ancrageY,
            lineHeight: ancrageY + 'px',
            position: 'absolute',
            borderStyle: 'solid dashed solid none',
            borderWidth: '1px',
            borderColor: couleurCotes,
            textAlign: 'center',
            overflow: 'hidden'
        }
*/
  const styleCotes = {
    display: props.hideText ? "none" : "block",
  }

  const styleTexteCotes = {
    padding: paddingCotes,
    fontSize: "10pt",
    color: couleurCotes,
  }
  const styleTexteCotesHauteur = {
    padding: paddingCotes,
    fontSize: "10pt",
    color: couleurCotes,
    position: "absolute",
    marginLeft: "-35px",
    transform: "rotate(-90deg)",
  }
  let styleImageDoublo
  if (props.doublo) {
    styleImageDoublo = {
      maxWidth: props.doublo.ba_largeur * ratio,
      maxHeight: props.doublo.ba_hauteur * ratio,
      position: "absolute",
      filter: "invert(59%) sepia(14%) saturate(5%) hue-rotate(12deg) brightness(88%) contrast(87%)", // https://codepen.io/sosuke/pen/Pjoqqp
      left: tailleCote + props.product.ba_talon * ratio,
      top: tailleCote + (hauteur - props.product.ba_hauteur_feuillure * ratio),
    }
  }
  let stylerehausse
  if (props.rehausse !== null) {
    stylerehausse = {
      position: "absolute",
      maxWidth: 1.6 * ratio,
      maxHeight: (props.rehausse == 1 ? 1.6 : 2.2) * ratio,
      filter: "invert(59%) sepia(14%) saturate(5%) hue-rotate(12deg) brightness(88%) contrast(87%)", // https://codepen.io/sosuke/pen/Pjoqqp
      left: tailleCote + (props.product.ba_talon * largeur) / props.product.ba_largeur + 2,
      top: tailleCote + ((props.product.ba_hauteur - props.product.ba_hauteur_feuillure) * hauteur) / props.product.ba_hauteur + 15,
    }
  }
  return (
    <>
      <div className="is-relative" style={styleCroquis}>
        <img style={styleImage} src={props.url} alt="croquis" />
        <div style={styleCotes}>
          <div style={styleLargeurCote}>
            <span style={styleTexteCotes}>{largeurCM.toFixed(2).replace(/(\.0|0)+$/, "")} cm</span>
          </div>
          <div style={styleHauteurCote}>
            <span style={styleTexteCotesHauteur}>{epaisseurCM.toFixed(2).replace(/(\.0|0)+$/, "")} cm</span>
          </div>
        </div>
        {props.doublo ? (
          <img style={styleImageDoublo} src={props.doublo.croquis_svg !== null ? props.doublo.croquis_svg : props.doublo.croquis} alt="croquis" />
        ) : null}
        {props.rehausse !== null && props.rehausse !== 0 ? <img src={rehausse} style={stylerehausse} alt="rehausse" /> : null}
      </div>
      {props.showHauteurFeuillure && !props.hideText ? (
        <div style={{ ...styleTexteCotes, marginTop: props.doublo ? 70 : 0 }}>Hauteur de feuillure : {props.feuillure} cm</div>
      ) : null}
    </>
  )
}

Croquis.defaultProps = {
  hideText: false,
  hauteurMax: 140,
}
Croquis.propTypes = {
  product: PropTypes.object,
  url: PropTypes.string,
  feuillure: PropTypes.number,
  doublo: PropTypes.object,
  rehausse: PropTypes.number,
  largeurFixe: PropTypes.number,
  tailleCote: PropTypes.number,
  showHauteurFeuillure: PropTypes.bool,
  hideText: PropTypes.bool,
  hauteurMax: PropTypes.number,
}

export default Croquis
