import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { setBaguetteDoublo } from "../../actions/cadre"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"
import { getPrix } from "../../services/panier"
import { tva } from "../../profcadresprix/index-legacy"
import ReactTooltip from "react-tooltip"
import { getEtape } from "@bko/optionsproduits"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ChoixDoublo = (props) => {
  const pageProduit = useSelector((state) => state.PageProduit)
  const photosR = useSelector((state) => state.photosR)
  const dispatch = useDispatch()

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  let categs = [
    { title: "Satiné", families: [1], products: [] },
    { title: "Laqué", families: [137], products: [] },
    { title: "Intemporel or et argent", families: [429], products: [] },
  ]
  const { baguette_ext, baguettes, produits_annexes, enableCategs } = props
  let options = baguettes.map((node) => {
    let price = getPrix(props.baguette_ext, { ...pageProduit, doublo: node }, produits_annexes, photosR)
    return {
      title: null,
      pu_ht: price / tva,
      pu_ttc: price,
      ba_id: node.ba_id,
      node,
    }
  })

  if (enableCategs) {
    baguettes.forEach((node) => {
      let price = getPrix(props.baguette_ext, { ...pageProduit, doublo: node }, props.produits_annexes, photosR)
      categs
        .find((c) => c.families.includes(node.fa_id))
        .products.push({
          title: null,
          pu_ht: price / tva,
          pu_ttc: price,
          ba_id: node.ba_id,
          node,
        })
    })
  }

  const renderColors = (familly) => {
    let options = []
    baguettes.forEach((node) => {
      let price = getPrix(props.baguette_ext, { ...pageProduit, doublo: node }, props.produits_annexes, photosR)
      options.push({
        pu_ht: price,
        pu_ttc: price * tva,
        ...node,
      })
    })
    return (
      <>
        {options
          .filter((e) => e.fa_id == familly)
          .sort((a, b) => a.co_order - b.co_order)
          .map((node, key) => (
            <div
              role="button"
              onKeyPress={() => {}}
              className="disableOutline"
              tabIndex="-2"
              key={`p-${key}`}
              style={{ display: "inline-block", margin:'5px 6px', border: pageProduit.doublo && pageProduit.doublo.ba_id === node.ba_id ? "3px solid red" : "0" }}
              onClick={() => dispatch(setBaguetteDoublo(node))}
            >
              <GatsbyImage style={{ height: "40px", width: "40px"}} alt={"Baguette1"} image={getImage(node.imgCoin)} />
            </div>
          ))}
      </>
    )
  }

  const renderText = (familly) => {
    return pageProduit.doublo && pageProduit.doublo.fa_id === familly ? (
      <>
        <div style={{ color: "#777", marginTop: 0, fontSize: "0.9em" }}>Couleur sélectionnée : {pageProduit.doublo.co_libelle.toLocaleLowerCase()}</div>
      </>
    ) : null
  }
  return (
    <>
      {baguette_ext.cat_id_originale !== 18 || baguette_ext.cat_id_originale === 18 ? (
        <div className="h2">
          {getEtape({...baguette_ext,cat_id:baguette_ext.cat_id_originale }, pageProduit, photosR) === "doublo" && <FontAwesomeIcon icon={faHandPointRight} className="cursor" />}
          Je sélectionne{" "}
          {props.baguette_ext.cat_id_originale === 18
            ? "le type et la couleur de ma caisse américaine doublo"
            : props.baguette_ext.cat_id_originale === 19
            ? "la finition de la marie louise (baguette intérieure)"
            : "la couleur de la baguette intérieure de mon cadre doublo design"}
        </div>
      ) : (
        ""
      )}
      {baguette_ext.cat_id_originale === 18 ? (
        <>
          <table className="table td-vcentered" style={{ marginTop: "0.5rem" }}>
            <tbody>
              <tr>
                <td>
                  <b style={{ fontSize: "1.3em" }}>Caisse américaine ATRIUM</b>
                  <br /> spécialement conçu pour les châssis d’une épaisseur jusqu’à 2.5cm
                  {renderText(7)}
                </td>
                <td>{renderColors(7)}</td>
              </tr>
              {baguettes.filter((e) => e.fa_id == 520).length >= 1 ? (
                <tr>
                  <td>
                    <b style={{ fontSize: "1.3em" }}>Caisse américaine XL ATRIUM</b>
                    <br /> spécialement conçu pour les châssis 3D d’une épaisseur de 3 à 4cm
                    {renderText(520)}
                  </td>
                  <td>{renderColors(520)}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </>
      ) : props.enableCategs ? (
        <>
          {categs
            .filter((c) => c.products.length >= 1)
            .map((categ, index) => (
              <>
                <div role="button" key={index} onKeyPress={() => {}} tabIndex={-2}>
                  <div className="h5">{categ.title}</div>
                </div>
                <div className=" mt-1 columns is-multiline">
                  {categ.products.map((node, key) => (
                    <div
                      className={`is-narrow is-mobile column choixDoubloB ${
                        pageProduit.doublo && pageProduit.doublo.ba_id == node.ba_id ? "active" : ""
                      }`}
                      key={`p-${key}`}
                      role="button"
                      style={{ height: 60, width: 130, cursor: "pointer", padding: 2 }}
                      data-for="choixdoublo"
                      data-tip={`${node.node.ba_descriptif}`}
                      tabIndex={0}
                      onKeyPress={() => {}}
                      onClick={() => dispatch(setBaguetteDoublo(node.node))}
                    >
                      {node.ba_id !== null ? (
                        <GatsbyImage style={{ height: "100%", width: "100%" }} alt={"Baguette1"} image={getImage(node.node.imgCoin)} />
                      ) : (
                        node.title
                      )}
                    </div>
                  ))}
                </div>
              </>
            ))}
        </>
      ) : (
        <div className="columns is-multiline">
          {options.filter(node => node?.node?.cat_id == 13).map((node, key) => (
            <div
              className={`is-narrow is-mobile column choixDoubloB ${pageProduit.doublo && pageProduit.doublo.ba_id == node.ba_id ? "active" : ""}`}
              key={`p-${key}`}
              role="button"
              style={{ height: 60, width: 130, cursor: "pointer", padding: 2 }}
              data-for="choixdoublo"
              data-tip={`${node.node.ba_descriptif}`}
              tabIndex={0}
              onKeyPress={() => {}}
              onClick={() => dispatch(setBaguetteDoublo(node.node))}
            >
              {node.ba_id !== null ? (
                <GatsbyImage style={{ height: "100%", width: "100%" }} alt={"Baguette1"} image={getImage(node.node.imgCoin)} />
              ) : (
                node.title
              )}
            </div>
          ))}
        </div>
      )}

      {isMounted ? <ReactTooltip effect="solid" id="choixdoublo" /> : null}
    </>
  )
}

ChoixDoublo.defaultProps = {
  enableCategs: false,
}
ChoixDoublo.propTypes = {
  produits_annexes: PropTypes.array,
  baguettes: PropTypes.array,
  baguette_ext: PropTypes.object,
  enableCategs: PropTypes.bool,
}

export default ChoixDoublo
