import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { options_pp } from "../../services/prixBaguettes"
import { useSelector, useDispatch } from "react-redux"
import { setPpType, setPpOuvertures } from "../../actions/passe"
import { mjsService, setRecouvrement } from "../../actions/cadre"
import PropTypes from "prop-types"

export const ChoixMultivues = (props) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const pageProduit = useSelector((state) => state.PageProduit)
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip
  
  useEffect(() => {
    if (typeof window !== `undefined` && typeof document !== `undefined` && !document.getElementById("PortalChoixMultivues")) {
      const el = document.createElement("div")
      el.id = "PortalChoixMultivues"
      document.getElementById("portal").appendChild(el)
    }

    setIsMounted(true);
  }, [])

  useEffect(() => {
    if (props.page === "choixPassePartout") {
      if (props.hauteur && props.largeur && !props.hideNoneMessage && (type === null || type=== "aucun" || type === "")) {// choix d'un passe obligatoire
        setVisible(true)
      }
    } else {
      setVisible(props.visible)
    }
    
  }, [props.visible])

  const { type, ouvertures } = pageProduit.pp
  return (
    <>
      {isMounted ? <ReactTooltip /> : null}
      <ChoixMultivuesPortal
        visible={visible}
        setVisible={setVisible}
        onComplete={props.onComplete}
        disableNone={props.disableNone}
        largeur={props.largeur}
        hauteur={props.hauteur}
        sizesMax={props.sizesMax}
        ignoreSizes={props.ignoreSizes}
        hideNoneMessage={props.hideNoneMessage}
      />
      {type !== null || props.page === "choixPassePartout" ? (
        <button
          data-effect="solid"
          data-tip={`Passe-partout ${type} : cliquer pour modifier`}
          className={`button is-secondary ml-2 ${!["aucun", null].includes(type) ? (props.page === "choixPassePartout" ? "is-primary" : "is-white") : ""}`}
          type="button"
          data-cy="edit-passe"
          onClick={() => {
            setVisible(true)
            dispatch(setPpType(null))
          }}
        >
          {props.text !== "" ? <span className="mr-2">{props.text}</span> : ""}
          {type === "aucun" && props.text === "" ? (
            "Sans passe-partout"
          ) : (
            <div className="miniPP">
              {Array.from({ length: ouvertures[1] }, (v, i) => i).map((krow) => (
                <div key={krow}>
                  {Array.from(Array(ouvertures[0])).map((col, kcol) => (
                    <div key={`${krow}-${kcol}`} className={`case ${ouvertures[0] === 1 && ouvertures[1] === 1 ? "solo" : ""}`}></div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </button>
      ) : null}
    </>
  )
}
ChoixMultivues.defaultProps = {
  text: "",
  ignoreSizes: false,
  hideNoneMessage: false,
  onComplete: () => {},
}
ChoixMultivues.propTypes = {
  visible: PropTypes.bool,
  onComplete: PropTypes.func,
  disableNone: PropTypes.bool,
  text: PropTypes.string,
  page: PropTypes.string,
  hauteur: PropTypes.any,
  largeur: PropTypes.any,
  sizesMax: PropTypes.object,
  ignoreSizes: PropTypes.bool,
  hideNoneMessage: PropTypes.bool,
}

export const ChoixMultivuesPortal = (props) => {
  const photosR = useSelector((s) => s.photosR)
  const [step, setStep] = useState(props.disableNone ? 1 : 0)
  const [understand, setUnderstand] = useState(Object.keys(photosR.photos).length !== 0)
  const pageProduit = useSelector((state) => state.PageProduit)
  const dispatch = useDispatch()

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  useEffect(() => {
    if ((Math.min(parseFloat(props.largeur), parseFloat(props.hauteur)) > 90 || Math.max(parseFloat(props.largeur), parseFloat(props.hauteur)) > 140) 
      && step < 2 && props.visible) {
      dispatch(setPpType("aucun"))
      props.setVisible(false)
      props.onComplete(true)
    }
  }, [props.hauteur, props.largeur, props.visible, step])
  useEffect(() => setStep(props.disableNone ? 1 : 0), [props.visible])
  useEffect(() => {
    if (["simple", "multivues"].includes(pageProduit.pp.type) && Object.keys(photosR.photos).length === 0) {
      dispatch(setRecouvrement())
    }
  }, [props.visible])

  const dispositions = props.ignoreSizes
    ? options_pp
    : options_pp.filter((option) => {
        const largeur = props.largeur * option[0] + (pageProduit.pp.margeG + pageProduit.pp.margeD) * option[0]
        const hauteur = props.hauteur * option[1] + (pageProduit.pp.margeH + pageProduit.pp.margeB) * option[1]
        return !(largeur > props.sizesMax.ba_maxi || hauteur > props.sizesMax.ba_maxi || largeur * 2 + hauteur * 2 > props.sizesMax.ba_perimetre_maxi)
      })

  return typeof document === `undefined` || document.getElementById("PortalChoixMultivues") === null
    ? null
    : ReactDOM.createPortal(
        <div className={`modal ${props.visible ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            {step === 0 ? (
              <>
                <header className="modal-card-head">
                  {isMounted ? <ReactTooltip id="custom-event" place="bottom" type="info" globalEventOff="click" /> : null}
                  <p className="modal-card-title">
                    Voulez vous un passe partout ?
                    <span
                      data-for="custom-event"
                      data-multiline="true"
                      data-tip="Un passe-partout est un encadrement en carton posé sur une œuvre d'art ou une reproduction.<br />
                      La fonction première du passe partout est de créer un espace entre l'œuvre et le verre de l'encadrement de façon à protéger l'œuvre d'un contact direct. Il assure également une fonction décorative.<br />
                      Idéal pour mettre en valeur les aquarelles, photos, sérigraphies, dessins, etc."
                      data-event="click focus"
                    >
                      <span className="not-active">
                        <small>
                          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                        </small>
                      </span>
                    </span>
                  </p>

                  <button
                    className="delete"
                    type="button"
                    aria-label="close"
                    data-cy="popup-passe-close"
                    onClick={() => {
                      props.setVisible(false)
                      props.onComplete(false)
                    }}
                  ></button>
                </header>
                <section className="modal-card-body has-text-centered">
                  <div className="columns is-centered py-6">
                    <button
                      className="button is-large mr-4"
                      data-cy="popup-passe-yes"
                      onClick={() => {
                        setStep(1)
                      }}
                    >
                      Oui
                    </button>
                    <button
                      className="button is-large"
                      data-cy="popup-passe-no"
                      onClick={() => {
                        dispatch(setPpType("aucun"))
                        props.setVisible(false)
                        props.onComplete(true)
                      }}
                    >
                      Non
                    </button>
                  </div>
                </section>
              </>
            ) : step == 1 ? (
              <>
                <header className="modal-card-head">
                  {isMounted ? <ReactTooltip id="custom-event" place="bottom" type="info" globalEventOff="click" /> : null}
                  <p className="modal-card-title">
                    Voulez vous un passe partout ?
                    <span
                      data-for="custom-event"
                      data-multiline="true"
                      data-tip="Un passe-partout est un encadrement en carton posé sur une œuvre d'art ou une reproduction.<br />
                      La fonction première du passe partout est de créer un espace entre l'œuvre et le verre de l'encadrement de façon à protéger l'œuvre d'un contact direct. Il assure également une fonction décorative.<br />
                      Idéal pour mettre en valeur les aquarelles, photos, sérigraphies, dessins, etc."
                      data-event="click focus"
                    >
                      <span className="not-active">
                        <small>
                          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                        </small>
                      </span>
                    </span>
                  </p>
                  {!props.disableNone && (
                    <button type="button" className="button mr-2" onClick={() => setStep(0)} data-cy="popup-passe-back">
                      Retour
                    </button>
                  )}
                  <button
                    className="delete"
                    type="button"
                    aria-label="close"
                    data-cy="popup-passe-close"
                    onClick={() => {
                      if (props.baMini && (props.largeur >= props.baMini || props.hauteur >= props.baMini)) {
                        dispatch(setPpType("aucun"))
                      }
                      
                      props.setVisible(false)
                      props.onComplete(false)
                    }}
                  ></button>
                </header>
                <section className="modal-card-body has-text-centered">
                  {props.disableNone ? (
                    !props.hideNoneMessage ? (
                      <div className="notification is-warning mb-6">
                        Les dimensions que vous avez saisies impliquent obligatoirement un passe partout. (minimum de coupe d’un cadre : 15cm).
                      </div>
                    ) : null
                  ) : (
                    ""
                  )}
                  {Object.keys(photosR.photos).length === 0 && !understand && (
                    <div className="notification is-info is-light">
                      Les dimensions de votre sujet ont été diminuées de 5mm de chaque côté afin d&apos;assurer son recouvrement par le passe-partout. <br />
                      <br />
                      Ceci n&apos;est qu&apos;un conseil, vous pouvez si vous le désirez mettre moins de recouvrement et changer les cotes.
                      <br />
                      <button
                        className="button mt-3"
                        data-cy="popup-passe-understood"
                        onClick={() => {
                          setUnderstand(true)
                          dispatch(setRecouvrement(true))
                        }}
                      >
                        J&apos;ai compris
                      </button>
                    </div>
                  )}
                  {understand && (
                    <div className="columns is-centered">
                      <div
                        className="typePp  t2 opacityChooser disableOutline"
                        onKeyPress={() => {}}
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                          dispatch(setPpType("simple"))
                          dispatch(mjsService("coupe_assemble_option"))
                          props.setVisible(false)
                          props.onComplete(true)
                        }}
                      >
                        <div className="tirage_multivues">
                          <div>
                            <div className="case">
                              <div className="fakePic"></div>
                            </div>
                          </div>
                        </div>
                        <div className="legende">
                          Une ouverture <br />
                          <button className="button" data-cy="popup-passe-1-ouverture">
                            Choisir
                          </button>
                        </div>
                      </div>
                      {dispositions.length >= 1 && (
                        <div
                          className="typePp t3 opacityChooser disableOutline"
                          onKeyPress={() => {}}
                          tabIndex={0}
                          role="button"
                          onClick={() => {
                            setStep(2)
                            dispatch(setPpType("multivues"))
                            dispatch(mjsService("coupe_assemble_option"))
                          }}
                        >
                          <div className="tirage_multivues">
                            <div>
                              <div className="case">
                                <div className="fakePic"></div>
                              </div>
                              <div className="case">
                                <div className="fakePic"></div>
                              </div>
                            </div>
                            <div>
                              <div className="case">
                                <div className="fakePic"></div>
                              </div>
                              <div className="case">
                                <div className="fakePic"></div>
                              </div>
                            </div>
                          </div>
                          <div className="legende">
                            Multivues
                            <br />
                            <button className="button" data-cy="popup-passe-multivues">
                              Choisir
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </section>
              </>
            ) : (
              <>
                <header className="modal-card-head">
                  <p className="modal-card-title">Choix du passe-partout </p>
                  <button type="button" className="button mr-2" onClick={() => setStep(1)} data-cy="popup-passe-back2">
                    Retour
                  </button>
                  <button className="delete" type="button" aria-label="close" onClick={() => props.setVisible(false)} data-cy="popup-passe-close2"></button>
                </header>
                <section className="modal-card-body has-text-centered">
                  {[...Array(5).keys()].map((nb) =>
                    dispositions.filter((oPp) => oPp[0] * oPp[1] === nb + 2).length === 0 ? (
                      ""
                    ) : (
                      <div key={nb} style={{ display: "inline-block", verticalAlign: "top", padding: 10 }}>
                        <div className="h3">{nb + 2} fenêtres</div>
                        {dispositions.map((oPp) =>
                          oPp[0] * oPp[1] === nb + 2 ? (
                            <div
                              key={`${oPp[0]}-${oPp[1]}`}
                              style={{ display: "inline-block" }}
                              className={`opacityChooser disableOutline`}
                              onClick={() => {
                                dispatch(setPpOuvertures(oPp))
                                props.setVisible(false)
                                props.onComplete(true)
                              }}
                              role="button"
                              onKeyPress={() => {}}
                              tabIndex={0}
                            >
                              <div className="tirage_multivues" style={{ marginRight: 15 }}>
                                {Array.from({ length: oPp[1] }, (v, i) => i).map((krow) => (
                                  <div key={krow}>
                                    {Array.from(Array(oPp[0])).map((col, kcol) => (
                                      <div key={`${krow}-${kcol}`} className="case">
                                        <div className="fakePic" style={{ background: ["blue", "red", "green", "orange", "purple", "lime"][nb] }}>
                                          {krow * oPp[0] + (kcol + 1)}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null
                        )}
                      </div>
                    )
                  )}
                </section>
              </>
            )}
          </div>
        </div>,
        document.getElementById("PortalChoixMultivues")
      )
}

export default ChoixMultivues
ChoixMultivuesPortal.propTypes = {
  setVisible: PropTypes.func,
  visible: PropTypes.bool,
  onComplete: PropTypes.func,
  disableNone: PropTypes.bool,
  text: PropTypes.string,
  page: PropTypes.string,
  hauteur: PropTypes.any,
  largeur: PropTypes.any,
  sizesMax: PropTypes.object,
  ignoreSizes: PropTypes.bool,
  hideNoneMessage: PropTypes.bool,
}
