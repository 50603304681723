import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { IdxImage } from "@bko/previsus"

export const DisplayTirage = props => {
  const photosR = useSelector(state => state.photosR)

  return (
    <div onClick={props.onClick} onKeyPress={() => {}} tabIndex={0} role="button" className="disableOutline">
      {Object.keys(photosR.photos).length > 1 ? (
        <div className="tirage_multivues">
          {Array.from({ length: props.rows }, (v, i) => i).map(krow => (
            <div key={krow}>
              {Array.from(Array(props.cols)).map((col, kcol) => (
                <div key={`${krow}-${kcol}`} className="case">
                  <IdxImage src={photosR.photos[krow * props.cols + (kcol + 1)].miniature}>
                    {({ image }) => <img src={image} alt="previsu multivues" />}
                  </IdxImage>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>
          <IdxImage src={photosR.photos[1].miniature}>{({ image }) => <img style={{ maxWidth: 200 }} src={image} alt="votre tirage" />}</IdxImage>
        </div>
      )}
    </div>
  )
}

DisplayTirage.propTypes = {
  cols: PropTypes.number,
  rows: PropTypes.number,
  onClick: PropTypes.func,
}
