import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { setDimensionsInvalid, setDimensions, setKitMontage, setCartonBarriere, setProtection, setRecouvrement, setIsorel } from "../actions/cadre"
import { setPpType, setPpMargeH, setPpMargeG, setPpMargeD, setPpMargeB } from "../actions/passe"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { categsNoPasse, getEtape } from "@bko/optionsproduits"

export const Dimensions = (props) => {
  const pageProduit = useSelector((state) => state.PageProduit)
  const photosR = useSelector((state) => state.photosR)
  const dispatch = useDispatch()
  const [showValidate, setShowValidate] = useState(false)

  const [typeIncorrect, setTypeIncorrect] = useState(null)
  const [dim, setDim] = useState({
    hauteur: pageProduit.dimensionsDefault ? "" : pageProduit.hauteur,
    largeur: pageProduit.dimensionsDefault ? "" : pageProduit.largeur,
  })

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  useEffect(() => {
    if (pageProduit.dimensionsDefault === false && pageProduit.recouvrement) {
      setDim({ hauteur: pageProduit.dimensionsDefault ? "" : pageProduit.hauteur, largeur: pageProduit.dimensionsDefault ? "" : pageProduit.largeur })
    }
  }, [pageProduit.recouvrement])

  const { product } = props

  useEffect(() => {
    if (pageProduit.dimensionsDefault) {
      setDim({ hauteur: "", largeur: "" })
    }
  }, [pageProduit.dimensionsDefault])

  const handleUpdate = (event) => {
    event.preventDefault()
    setDim({ ...dim, [event.target.name]: event.target.value })
    setTypeIncorrect(null)
  }

  const handleSubmit = () => {
    setShowValidate(false)
    if (
      dim.largeur === "" ||
      dim.hauteur === "" ||
      parseFloat(dim.largeur).toString() !== dim.largeur.toString() ||
      parseFloat(dim.hauteur).toString() !== dim.hauteur.toString() ||
      isNaN(dim.hauteur) ||
      isNaN(dim.largeur)
    ) {
      setTypeIncorrect("dimensions")
      dispatch(setDimensionsInvalid())

      return false
    }

    if (
      !(
        parseFloat(dim.hauteur) < product.ba_mini ||
        parseFloat(dim.largeur) < product.ba_mini ||
        parseFloat(dim.hauteur) > product.ba_maxi ||
        parseFloat(dim.largeur) > product.ba_maxi
      )
    ) {
      let perimetre = 0
      perimetre = (((parseFloat(dim.hauteur) - 0.5) * 2 + (parseFloat(dim.largeur) - 0.5) * 2) * (product.ba_chutes / 100 + 1)) / 100

      if (product.stock < perimetre && product.ba_continue !== 5) {
        alert("Stock insuffisant pour ces dimensions")
        dispatch(setDimensionsInvalid())
        setTypeIncorrect("stock")
        return false
      }

      dispatch(setDimensions(parseFloat(dim.largeur), parseFloat(dim.hauteur), true))
      dispatch(setPpType(null))
      dispatch(setKitMontage(null))
      //dispatch(setIsorel(null))
      dispatch(setCartonBarriere(null))
      dispatch(setProtection(null))
      //dispatch(mjsService(null))
      if (props.init) {
        props.init()
      }
    } else {
      if (
        parseFloat(dim.largeur) >= 6.5 &&
        parseFloat(dim.hauteur) >= 6.5 &&
        parseFloat(dim.hauteur) < product.ba_maxi &&
        parseFloat(dim.largeur) < product.ba_maxi &&
        product.fa_id !== 475 &&
        !categsNoPasse.includes(product.cat_id_originale)
      ) {
        setTypeIncorrect("only_passe_partout")
        dispatch(setDimensions(parseFloat(dim.largeur), parseFloat(dim.hauteur), true))
        dispatch(setRecouvrement())
        dispatch(setIsorel(true))
        if (dim.largeur <= product.ba_mini) {
          dispatch(setPpMargeG(Math.ceil((product.ba_mini - parseFloat(dim.largeur)) / 2)))
          dispatch(setPpMargeD(Math.ceil((product.ba_mini - parseFloat(dim.largeur)) / 2)))
        }
        if (dim.hauteur <= product.ba_mini) {
          dispatch(setPpMargeH(Math.ceil((product.ba_mini - parseFloat(dim.hauteur)) / 2)))
          dispatch(setPpMargeB(Math.ceil((product.ba_mini - parseFloat(dim.hauteur)) / 2)))
        }
      } else {
        dispatch(setDimensionsInvalid())
        setTypeIncorrect("dimensions")
      }
    }
  }

  return (
    <>
      {isMounted ? <ReactTooltip id="product" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
      <div className="h2">
        {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photosR) === "dimensions" && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}
        Je renseigne les dimensions de mon sujet
        <span
          data-for="product"
          data-multiline="true"
          data-tip={`Renseignez les dimensions réelles du sujet : nous coupons le cadre 2 à 3mm + grand afin d'assurer un jeu parfait entre votre sujet et l'encadrement ${
            ["simple", "multivues"].includes(pageProduit.pp.type)
              ? "Les dimensions de votre sujet ont été diminuées de 5mm de chaque côté afin d'assurer son recouvrement par le passe-partout."
              : ""
          }`}
        >
          <span className="not-active">
            <small>
              <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
            </small>
          </span>
        </span>
      </div>
      <div className="notification is-info is-light">Les dimensions que vous renseignez sont toujours celles de votre sujet que vous glissez dans le cadre</div>
      <div className="field">
        <div className="field-body">
          <div className="field sizesNotSet">
            {product.fa_id === 475 ? (
              <p>
                Ce cadre entre 2 verres est spécialement prévu pour un montage entre 2 verres donnant un effet de transparence. <br />
                Nous ajoutons automatiquement une marge de 5cm de chaque côté : si votre sujet mesure <b>30x40cm</b>, votre cadre sera en dimensions intérieures{" "}
                <b>40x50cm</b>.
              </p>
            ) : (
              ""
            )}
            <div className="control is-flex-direction-row	is-flex">
              <div data-info="horizontal">
                <input
                  type="number"
                  name="largeur"
                  placeholder=" " // sert à ne pas afficher une erreur
                  value={dim.largeur}
                  onChange={handleUpdate}
                  className="input input-number is-large mx-1"
                  onFocus={() => {
                    setDim({ ...dim, largeur: "" })
                    dispatch(setDimensionsInvalid())
                    setShowValidate(true)
                  }}
                  min={6}
                  max={product.ba_max}
                  step=".1"
                  required
                />
              </div>
              <span style={{ position: "relative", top: 12, fontSize: "1.2em" }}>
                cm<span className="mx-4 has-text-grey-light">X</span>{" "}
              </span>
              <div data-info="vertical">
                <input
                  type="number"
                  placeholder=" " // sert à ne pas afficher une erreur
                  name="hauteur"
                  value={dim.hauteur}
                  onChange={handleUpdate}
                  className="input input-number is-large mx-1"
                  onFocus={() => {
                    setDim({ ...dim, hauteur: "" })
                    dispatch(setDimensionsInvalid())
                    setShowValidate(true)
                  }}
                  onKeyPress={(e) => (e.key == "Enter" ? handleSubmit() : null)}
                  onBlur={() => handleSubmit()}
                  min={6}
                  max={product.ba_max}
                  step=".1"
                  required
                />
              </div>
              <span style={{ position: "relative", top: 12, fontSize: "1.2em" }}>cm </span>
              <small className="has-text-grey" style={{ position: "relative", top: 12 }}>
                (min {product.ba_mini}cm / max {product.ba_maxi}cm)
              </small>

              {showValidate && (
                <div className="button is-primary ml-1 " onKeyPress={() => {}} role="button" tabIndex={0} onClick={
                  (e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});handleSubmit()}
                }>
                  Valider
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {typeIncorrect == "only_passe_partout" ? (
        <div className="notification is-info">Ces dimensions ne sont éligibles qu&apos;avec un passe partout</div>
      ) : null}
      {pageProduit.dimensionsValid === false && typeIncorrect !== null ? (
        <div className="notification is-danger">
          <>
            Une des dimensions n&apos;est pas comprise entre {product.ba_mini} et {product.ba_maxi}cm
          </>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
Dimensions.default = {
  init: null,
}
Dimensions.propTypes = {
  product: PropTypes.object,
  init: PropTypes.func,
}

export default Dimensions
