import React, { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera, faTrash } from "@fortawesome/free-solid-svg-icons"
import { setPrevisuPhoto } from "../actions/photos"
import { useDispatch, useSelector } from "react-redux"

export const ImporterPhoto = () => {
  const inputFile = useRef(null)
  const dispatch = useDispatch()
  const photosR = useSelector(s => s.photosR)
  const [hidden, setHidden] = useState(true)

  const _handleImageChange = e => {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      //  setFile(file)
      dispatch(setPrevisuPhoto(reader.result))
    }

    reader.readAsDataURL(file)
  }

  return photosR.previsuPhoto === null ? (
    !hidden ? (
      <>
        <div className="notification is-danger">
          Le cadre sera livré sans la photo
          <input className="fileInput" ref={inputFile} type="file" style={{ display: "none" }} onChange={e => _handleImageChange(e)} />
          <div className="button mt-2" onKeyPress={() => {}} role="button" tabIndex={0} onClick={() => inputFile.current.click()}>
            <FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />
            Choisir ma photo
          </div>
        </div>
      </>
    ) : (
      <div className="button" onKeyPress={() => {}} role="button" tabIndex={0} onClick={() => setHidden(false)}>
        <FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />
        J’importe la photo de mon sujet
      </div>
    )
  ) : (
    <>
      <div className="button" onKeyPress={() => {}} role="button" tabIndex={0} onClick={() => dispatch(setPrevisuPhoto(null))}>
        <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
        Supprimer la photo
      </div>
      <div className="notification is-info mt-4">Le cadre sera livré sans la photo</div>
    </>
  )
}

export default ImporterPhoto
