import React, { useEffect, useState } from "react"
import { setCartonBarriere } from "../../actions/cadre"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faHandPointRight, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import { getEtape } from "@bko/optionsproduits"

const ChoixCartonBarriere = props => {
  const pageProduit = useSelector(state => state.PageProduit)
  const photosR = useSelector(state => state.photosR)
  const dispatch = useDispatch()
  const {product} = props

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);

    if ((pageProduit.cartonBarriere === null || pageProduit.cartonBarriere === true)
      && (Math.min(parseFloat(pageProduit.largeur), parseFloat(pageProduit.hauteur)) > 91.5
        || Math.max(parseFloat(pageProduit.largeur), parseFloat(pageProduit.hauteur)) > 122)) {
      dispatch(setCartonBarriere(false));
    }
  },[]);

  return (
    <>
      <div className="is-flex is-multiline" style={{alignItems: "center"}}>
        <div className="h2">
          {getEtape({...product,cat_id:product.cat_id_originale }, pageProduit, photosR) === "cartonBarriere" && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}
          {isMounted ? <ReactTooltip id="cb" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
          Je désire un carton barrière conservation
          <span data-multiline="true" data-for="cb" data-tip="Se positionne entre le sujet et le fond isorel pour protéger votre sujet">
            <small>
              <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
            </small>
          </span>
        </div>
        <div className="choix_isorel yesno ml-2" style={{ height: "2rem" }}>
          <button
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});dispatch(setCartonBarriere(true))}}
            role="radio"
            aria-checked={pageProduit.cartonBarriere}
            tabIndex={0}
            className={`button ${pageProduit.cartonBarriere ? "selected" : ""}`}
            onKeyPress={() => {}}
            disabled={Math.min(parseFloat(pageProduit.largeur), parseFloat(pageProduit.hauteur)) > 91.5 || Math.max(parseFloat(pageProduit.largeur), parseFloat(pageProduit.hauteur)) > 122}
          >
            <span>Oui</span>
            <FontAwesomeIcon icon={faCheck} className="ml-2" />
          </button>
          <button
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});dispatch(setCartonBarriere(false))}}
            role="radio"
            aria-checked={pageProduit.cartonBarriere === false}
            tabIndex={0}
            className={`ml-2 button ${pageProduit.cartonBarriere === false ? "selected" : ""}`}
            onKeyPress={() => {}}
          >
            <span>Non</span>
            <FontAwesomeIcon icon={faTimes} className="ml-2" />
          </button>
        </div>
      </div>
    </>
  )
}
ChoixCartonBarriere.propTypes = {
  product: PropTypes.object,
  pa: PropTypes.array,
}
export default ChoixCartonBarriere
